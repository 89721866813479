import React, { useState } from "react";
import { Empty } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import BranchOptionViewHeading from "./BranchOptionViewHeading/BranchOptionViewHeading";
import imageLinks from "assets/images";
import BranchOption from "./BranchOption/BranchOption";
import CreateBranchOptionModal from "./modals/CreateBranchOptionModal/CreateBranchOptionModal";
import { branchOptionActions } from "./BranchOption/enum";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResources, baseResourceActions } from "routes/enum";
import ConfirmationModal from "components/dashboard/common/ConfirmationModal/ConfirmationModal";
import { apiRoute } from "services";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reOrderList } from "utils/helper";
import "./BranchOptionView.scss";

const {
    KB_SAM_BRANCH,
    KB_SAM_COLLECTION,
    AGENTS_INBOX_SERVICE_BRANCH_SETTING,
    KB_SAM_ISSUE_CONVERSATION,
} = baseResources;
const { CREATE } = baseResourceActions;

const {
    CREATE_BRANCH_OPTION,
    EDIT_BRANCH_OPTION,
    CREATE_CONVERSATION_BREAKER_OPTION,
    EDIT_CONVERSATION_BREAKER_OPTION,
} = branchOptionActions;

const BranchOptionView = ({
    parentBranch,
    handleRefresh,
    hideCapsuleContentGroup,
    isConversationBreakerView,
    existingOptionConnections,
    isAgentGuideView,
}) => {
    const { branchOptions, guideFlowOptions, guideFlowId, guideId } =
        parentBranch || {};
    const [showEditModal, toggleEditModal] = useState(false);
    const [showDeleteModal, toggleDeleteModal] = useState(false);
    const [modalActionType, updateModalActionType] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [optionElements, setOptionElements] = useState(
        isAgentGuideView ? guideFlowOptions : branchOptions
    );
    const [showSaveChangesBtn, toggleSaveChangesBtn] = useState(false);
    const [sortOptions, toogleSortOptions] = useState(true);

    const handleSelectOption = (option) => {
        setSelectedOption(option);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const elements = reOrderList(
            optionElements,
            result.source.index,
            result.destination.index
        );

        setOptionElements(elements);
        toggleSaveChangesBtn(true);
        toogleSortOptions(false);
    };

    const optionsPayload = optionElements?.map?.(
        ({ branchOptionId }, order) => ({
            branchOptionId,
            order: order + 1,
        })
    );

    const options = sortOptions
        ? optionElements?.sort((a, b) => a?.order - b?.order)
        : optionElements;

    console.log("selectedOption", selectedOption);
    return (
        <div>
            <BranchOptionViewHeading
                toggleModal={toggleEditModal}
                updateModalActionType={updateModalActionType}
                isConversationBreakerView={isConversationBreakerView}
                branchOptionsCount={branchOptions?.length}
                optionsPayload={optionsPayload}
                showSaveChangesBtn={showSaveChangesBtn}
                handleSuccess={() => {
                    toggleSaveChangesBtn(false);
                }}
                handleCancel={() => {
                    toggleSaveChangesBtn(false);
                    toogleSortOptions(true);
                }}
                isAgentGuideView={isAgentGuideView}
            />

            {optionElements?.length > 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div>
                        <Droppable droppableId='option-droppable' index={1}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className='row'>
                                    {options?.map((option, index) => (
                                        <Draggable
                                            draggableId={option.branchOptionId}
                                            key={option.branchOptionId}
                                            index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className='col-lg-6'
                                                    key={index}>
                                                    <BranchOption
                                                        option={option}
                                                        parentBranch={
                                                            parentBranch
                                                        }
                                                        handleRefresh={
                                                            handleRefresh
                                                        }
                                                        handleSelectOption={
                                                            handleSelectOption
                                                        }
                                                        toggleEditModal={
                                                            toggleEditModal
                                                        }
                                                        toggleDeleteModal={
                                                            toggleDeleteModal
                                                        }
                                                        updateModalActionType={
                                                            updateModalActionType
                                                        }
                                                        isConversationBreakerView={
                                                            isConversationBreakerView
                                                        }
                                                        isAgentGuideView={
                                                            isAgentGuideView
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {branchOptions?.length < 4 && (
                            <div className='row'>
                                <div className='col-2'>
                                    <ComponentAccessControl
                                        baseResources={[
                                            `${CREATE}_${KB_SAM_BRANCH}`,
                                            `${CREATE}_${KB_SAM_COLLECTION}`,
                                            `${CREATE}_${AGENTS_INBOX_SERVICE_BRANCH_SETTING}`,
                                            `${CREATE}_${KB_SAM_ISSUE_CONVERSATION}`,
                                        ]}>
                                        <AddButton
                                            btnText={`Add Another Option`}
                                            otherClassName={
                                                "justify-content-center"
                                            }
                                            onClick={() => {
                                                updateModalActionType(
                                                    isConversationBreakerView
                                                        ? CREATE_CONVERSATION_BREAKER_OPTION
                                                        : CREATE_BRANCH_OPTION
                                                );
                                                toggleEditModal(true);
                                            }}
                                        />
                                    </ComponentAccessControl>
                                </div>
                            </div>
                        )}
                    </div>
                </DragDropContext>
            ) : (
                <>
                    {isAgentGuideView ? (
                        <p className='no-next-step-text'>
                            No next steps added yet
                        </p>
                    ) : (
                        <Empty
                            className={"empty__container"}
                            text='No option has been created yet.'
                            icon={imageLinks?.images?.optionCollection}
                        />
                    )}
                </>
            )}

            {showEditModal && (
                <CreateBranchOptionModal
                    show={showEditModal}
                    isEdit={
                        isConversationBreakerView
                            ? modalActionType ===
                              EDIT_CONVERSATION_BREAKER_OPTION
                                ? true
                                : false
                            : modalActionType === EDIT_BRANCH_OPTION
                            ? true
                            : false
                    }
                    closeModal={() => toggleEditModal(false)}
                    referenceData={{
                        ...selectedOption,
                        ...parentBranch,
                        guideId,
                        guideFlowId,
                    }}
                    handleSuccess={(branchId) => {
                        toggleEditModal(false);
                        handleRefresh(branchId);
                    }}
                    hideCapsuleContentGroup={hideCapsuleContentGroup}
                    modalActionType={modalActionType}
                    existingOptionConnections={existingOptionConnections}
                    isAgentGuideView={isAgentGuideView}
                />
            )}

            {showDeleteModal && (
                <ConfirmationModal
                    title={`Are you sure you want to delete ${
                        isAgentGuideView
                            ? selectedOption?.guideFlowOptionLabel
                            : selectedOption?.branchOptionLabel
                    }`}
                    show={showDeleteModal}
                    closeModal={() => toggleDeleteModal(false)}
                    handleSuccess={() => {
                        toggleDeleteModal(false);
                        handleRefresh(parentBranch?.branchId);
                    }}
                    url={
                        isAgentGuideView
                            ? apiRoute?.deleteGuideFlowOption(
                                  selectedOption?.guideFlowOptionId
                              )
                            : isConversationBreakerView
                            ? apiRoute?.conversationBreakerOption(
                                  parentBranch?.branchId,
                                  selectedOption?.branchOptionId
                              )
                            : apiRoute?.deleteBranchOption(
                                  parentBranch?.branchId,
                                  selectedOption?.branchOptionId
                              )
                    }
                    method='delete'
                />
            )}
        </div>
    );
};

export default BranchOptionView;
