import { useEffect, useState } from "react";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";

const IssuesSelect = ({
    name = "issuesId",
    handleChange,
    defaultValue
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");
    const [issues, setIssues] = useState([]);

    const fetchIssues = async (search) => {
        try {
            setErrorMssg();
            setLoading(true);
            const url = apiRoute.issues;
            const res = await API.get(url, {
                params: {
                    search,
                }
            });

            if (res.status === 200) {
                const { data } = res.data;
                setIssues(
                    data?.map((issue) => {
                        return {
                            label: issue?.issueName,
                            value: issue?.issueId,
                            ...issue,
                        };
                    })
                );
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        fetchIssues("")
        // eslint-disable-next-line
    }, [])

    return (
        <div className='form-group mb-3'>
            <SelectUI
                label={'Relate Issues'}
                placeholder={`Select Issues`}
                options={issues}
                defaultValue={defaultValue}
                onChange={handleChange}
                isMultiSelection={true}
                hasOptionIcon={true}
                isLoading={loading}
                serverError={errorMssg}
                closeMenuOnSelect={false}
                isRequired={false}
                handleApiCall={(searchTerm) => fetchIssues(searchTerm)}
                callApi
                darkLabel
            />
        </div>
    );
};

export default IssuesSelect;
