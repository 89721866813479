import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QaDocumentationTable from "./QaDocumentationTable/QaDocumentationTable";
import QaDocumentationHeader from "./QaDocumentationHeader/QaDocumentationHeader";
import { dataQueryStatus } from "utils";
import API from "services/lib/api";
import { apiRoute } from "services";
import { Empty, Loading } from "components/ui";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { getErrorMessage, getPageFromCache } from "utils/helper";
import CustomPagination from "components/dashboard/common/CustomPagination/CustomPagination";
import CreateQaDocumentationModal from "./QaDocumentationModal/CreateQaDocumentationModal/CreateQaDocumentationModal";
import Filter from "components/dashboard/common/Filter/Filter";
import { clearQaDocumentations, saveQaDocumentations } from "store/modules/sam/actions";
import { setQADocumentationBaseFilter, setQADocumentationFilterOption, setQADocumentationFilterParams } from "store/filters/actions";


const { IDLE, LOADING, DATAMODE, NULLMODE, ERROR } = dataQueryStatus;

const QaDocumentation = () => {
    const [showModal, toggleModal] = useState(false);
    const closeModal = () => toggleModal(false);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [useTableLoader, setUseTableLoader] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState();
    const { baseFilter, filterOption, filterParams } = useSelector(
        (state) => state?.filters?.qaDocumentationFilters
    );
    const [qaDocumentations, setQaDocumentations] = useState([]);
    const {
        qaDocumentationCache: { pages, totalRecords },
    } = useSelector((state) => state?.sam);
    const [status, setStatus] = useState(
        pages[filterParams?.currentPage]?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const getQaDocumentation = async (useTableLoader, httpRequest) => {
        try {
            setErrorMssg();

            useTableLoader && setTableIsLoading(true);
            if (qaDocumentations?.length < 1 && filterParams?.search < 1) {
                setStatus(LOADING);
            }

            const url = apiRoute?.fetchQaDocumentation;
            const res = await API.get(url, {
                params: { ...filterParams },
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                filterParams?.search?.length > 0 && setUseTableLoader(true);
                const { data, metaData } = res.data;
                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setQaDocumentations(data);
                dispatch(
                    saveQaDocumentations(
                        filterParams?.currentPage,
                        metaData?.totalRecords,
                        data
                    )
                );
                setStatus(newStatus);
                setSearchIsLoading(false);
                setTableIsLoading(false);
            }
        } catch (err) {
            setSearchIsLoading(false);
            setErrorMssg(getErrorMessage(err));
            useTableLoader ? setTableIsLoading(false) : setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        const cachedPage = getPageFromCache(pages, filterParams.currentPage);
        if (cachedPage?.length > 0 && !searchIsLoading) {
            setQaDocumentations(cachedPage);
        } else {
            getQaDocumentation(useTableLoader, httpRequest);
        }

        return () => {
            if (!useTableLoader) {
                httpRequest.abort();
            }
        };
        // eslint-disable-next-line
    }, [filterParams, useTableLoader]);

    const handleSearch = (search) => {
        setUseTableLoader(true);
        setSearchIsLoading(true);
        setClearSearch(false);
        dispatch(setQADocumentationFilterParams({ currentPage: 1, search }));
        dispatch(clearQaDocumentations());
    };

    const handlePageChange = (currentPage) => {
        console.log("currentPage", currentPage);
        setUseTableLoader(true);
        dispatch(setQADocumentationFilterParams({ ...filterParams, currentPage }));
    };

    const handleBasefilter = (baseFilter) => {
        dispatch(setQADocumentationBaseFilter(baseFilter));

        dispatch(setQADocumentationFilterOption({}));
    };

    const handleSuccess = () => {
        setClearSearch(true);
        dispatch(clearQaDocumentations());
        getQaDocumentation();
    };

    const handleSortByAlpha = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setQADocumentationFilterParams({
                ...filterParams,
                alphabetical: value,
                dateCreated: null,
                lastModified: null,
            })
        );
        dispatch(setQADocumentationFilterOption({ ...option, value }));
        dispatch(clearQaDocumentations());
    };

    const handleSortByDate = (value, option) => {
        setUseTableLoader(true);
        dispatch(
            setQADocumentationFilterParams({
                ...filterParams,
                dateCreated: value,
                alphabetical: null,
                lastModified: null,
            })
        );
        dispatch(setQADocumentationFilterOption({ ...option, value }));
        dispatch(clearQaDocumentations());
    };

    const handleSortByLastModified = (value, option) => {
        dispatch(
            setQADocumentationFilterParams({
                ...filterParams,
                dateCreated: null,
                alphabetical: null,
                lastModified: value,
            })
        );
        dispatch(setQADocumentationFilterOption({ ...option, value }));
        dispatch(clearQaDocumentations());
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={getQaDocumentation} />
                );

            case NULLMODE:
                return (
                    <Empty
                        text={
                            useTableLoader
                                ? "No Issue matches the search."
                                : "No Issue has been created yet."
                        }
                    />
                );

            case DATAMODE:
                return (
                    <>
                        <QaDocumentationTable
                            qaDocumentations={qaDocumentations}
                            tableIsLoading={tableIsLoading}
                            errorMssg={errorMssg}
                            handleSuccess={handleSuccess}
                        />
                        <CustomPagination
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            {...filterParams}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    return (
        <div className='padded__layout'>
            <div className='QaDocumentation'>
                <QaDocumentationHeader toggleModal={toggleModal} />
                <Filter
                    handleSortByAlpha={handleSortByAlpha}
                    handleSortByDate={handleSortByDate}
                    hideClassFilter={true}
                    handleSearch={handleSearch}
                    searchIsLoading={searchIsLoading}
                    clearSearch={clearSearch}
                    searchPlaceholder={"Search QaDocumentation here"}
                    handleSortByLastModified={handleSortByLastModified}
                    baseFilter={baseFilter}
                    filterOption={filterOption}
                    handleBasefilter={handleBasefilter}
                    searchDefaultValue={filterParams?.search}
                />
                {renderBasedOnStatus()}
            </div>
            {showModal && (
                <CreateQaDocumentationModal
                    closeModal={closeModal}
                    handleSuccess={handleSuccess}
                    showModal={showModal}
                />
            )}
        </div>
    );
};

export default QaDocumentation;
